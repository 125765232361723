import React from "react"
import { Layout, SEO } from "@global"
import { WhatAreYouLookingFor } from "@layouts"
import { useGlobalModules } from "@hooks"

const NotFoundPage = () => {
  const options = useGlobalModules()
  return (
    <Layout>
      <SEO title="404: Not found" />
      <section
        className={`module-centered-content relative z-20 py-10 -mb-12 text-center lg:pt-20 sm:-mb-16 lg:-mb-20`}
      >
        <div className="px-4">
          <div className="max-w-4xl px-4 py-10 mx-auto bg-white shadow-xl md:py-12 lg:py-24">
            <h1 className="text-3xl font-light leading-tight md:text-4xl xl:text-5xl font-display">
              404: Page Not Found
            </h1>
            <p className="max-w-3xl mx-auto mt-6 wysiwyg text-gray-200">
              But that doesn’t mean we can’t find you a loan. Let’s try this
              again.
            </p>
          </div>
        </div>
      </section>
      <WhatAreYouLookingFor
        global={options.whatAreYouLookingFor}
        cssClasses="mt-16"
      />
    </Layout>
  )
}

export default NotFoundPage
